// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/Component/FrequencyChip.js

import React, { useState, useEffect } from 'react';
import { Chip } from 'primereact/chip';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'; 
import { ProgressSpinner } from 'primereact/progressspinner';
import 'swiper/css';

// Helper function to remove query term and truncate titles
const cleanAndTruncateTitle = (title, query, length = 30) => {
    const cleanTitle = title.replace(new RegExp(query, 'gi'), '').trim(); // Remove all occurrences of query (case-insensitive)
    return cleanTitle.length > length ? `${cleanTitle.substring(0, length)}...` : cleanTitle;
};

function FrequencyChip({ results, wordFrequency, query }) {
    const [processedResults, setProcessedResults] = useState([]);
    const [chipLoading, setChipLoading] = useState(true);

    // Process titles asynchronously
    useEffect(() => {
        const processTitles = async () => {
            const updatedResults = results.map(post => ({
                ...post,
                displayTitle: cleanAndTruncateTitle(post.post_title, query), // Clean and truncate title
                wordFrequency: post.post_title
                    .split(/\s+/)
                    .reduce((acc, word) => acc + (wordFrequency[word] || 0), 0),
            }));
            setProcessedResults(updatedResults.sort((a, b) => b.wordFrequency - a.wordFrequency)); // Sort by word frequency
            setChipLoading(false); // Loading complete
        };

        processTitles();
    }, [results, query, wordFrequency]);

    return (
        <div className="chip-container">
            {chipLoading ? (
                <ProgressSpinner />
            ) : (
                <Swiper
                spaceBetween={10}
                slidesPerView="auto"
                freeMode={true}
                grabCursor={true}
                centeredSlides={false}
                navigation={true}                 // Enable navigation arrows
                modules={[Navigation]}            // Register the Navigation module
                breakpoints={{
                    0: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },
                    480: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                }}
                touchStartPreventDefault={false}
            >
                {processedResults.map((post, index) => {
                    const colorClass = index < 5 ? "high-frequency" : index < 10 ? "medium-frequency" : "low-frequency";

                    return (
                        <SwiperSlide key={post.ID}>
                            <a
                                href={`https://www.xn--12cah8fwbj2hqf.com/post/${post.ID}/${encodeURIComponent(post.post_title.replace(/ /g, '-'))}`}
                                rel="noopener noreferrer"
                            >
                                <Chip
                                    label={post.displayTitle}
                                    className={`word-chip ${colorClass}`}
                                />
                            </a>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            )}
        </div>
    );
}

export default FrequencyChip;
