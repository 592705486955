// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/Component/SearchResults.js

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import { Paginator } from 'primereact/paginator';
import FrequencyChip from './FrequencyChip';
import '../styles/SearchResults.scss';

function SearchResults() {
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('q');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [wordFrequency, setWordFrequency] = useState({});
    const [totalCount, setTotalCount] = useState(0);

    // Pagination state
    const [first, setFirst] = useState(0);
    const rows = 30; // Show 30 items per page

    // Fetch results based on pagination and query
    useEffect(() => {
        if (query) {
            const fetchResults = async () => {
                setLoading(true);
                try {
                    const offset = first;
                    const response = await fetch(`https://py.afaa.website/blog/api/game?offset=${offset}&limit=${rows}&order_by=ID&order_direction=DESC&title=${query}&content=${query}`);
                    const data = await response.json();

                    if (data && data.posts) {
                        // Calculate word frequencies
                        const allWords = data.posts.flatMap(post => post.post_title.split(/\s+/));
                        const wordCount = array_count_values(allWords);
                        const sortedWordCount = arsort(wordCount);

                        setWordFrequency(sortedWordCount);
                        setResults(data.posts);   // Set posts from new data structure
                        setTotalCount(data.total_count || 0); // Set total_count for pagination
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            };
            fetchResults();
        }
    }, [query, first]);

    // Utility functions for counting and sorting words
    function array_count_values(arr) {
        return arr.reduce((acc, curr) => {
            acc[curr] = (acc[curr] || 0) + 1;
            return acc;
        }, {});
    }

    function arsort(obj) {
        const sortable = Object.entries(obj).sort((a, b) => b[1] - a[1]);
        return Object.fromEntries(sortable);
    }

    // Format date to DD/MM/YYYY
    function formatDate(dateStr) {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }

    // Handle pagination change
    const onPageChange = (e) => {
        setFirst(e.first); // Update the offset for pagination
    };

    // Function to decode the query string
function decodeQuery(query) {
    return decodeURIComponent(query);
}


    return (
        <div className="search-results-container">
           <h4>AA-Chats Search : {decodeQuery(query)}</h4>

            <p className="result-count">{totalCount} results</p>
            
            {/* Render FrequencyChip as a container */}
            <FrequencyChip results={results} wordFrequency={wordFrequency} />

            {loading ? (
                <div className="spinner-container">
                    <ProgressSpinner />
                </div>
            ) : (
                <div className="results-grid">
                    {results.length > 0 ? (
                        results.map(post => (
                            <div key={post.ID} className="result-item">
                                <a href={`https://blog.ttt-website.com/?p=${post.ID}`} rel="noopener noreferrer">
                                    <Card title={post.post_title} subTitle={post.post_excerpt}>
                                        <img
                                            src={post.images && post.images[0]?.guid ? post.images[0].guid : 'https://afaa.website/s/ce99ba.webp'}
                                            alt={post.post_title}
                                            onError={(e) => e.target.src = 'https://afaa.website/s/ce99ba.webp'}
                                            className="result-image"
                                        />
                                    </Card>
                                </a>
                                <p className='fontx'>{formatDate(post.post_modified)}</p>
                            </div>
                        ))
                    ) : (
                        <p>No results found for your AA-Chats Search.</p>
                    )}
                </div>
            )}

            {/* Add Paginator */}
            <Paginator
                first={first}
                rows={rows}
                totalRecords={totalCount}
                onPageChange={onPageChange}
                rowsPerPageOptions={[30]}
            />
        </div>
    );
}

export default SearchResults;
