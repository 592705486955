// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/Component/Footer.js

import React from 'react';
import '../styles/Footer.scss';  // Ensure you create this SCSS file

const Footer = () => {
  return (
    <footer className="custom-footer">
      <p> 
        <a href='https://blog.ttt-website.com/'>BLOG.TTT-WEBSITEM</a> X KHONRAKGAME | 
        <a href='https://afraapache.com/'> AFRA APACHE</a>
      </p>

      <p> 
        <a className='color-index1' href='https://afraapache.com/privacypolicy'> Privacy Policy</a> | 
        <a className='color-index1' href='https://afraapache.com/termsandconditions'> Terms and Conditions</a>
      </p>
    </footer>
  );
};

export default Footer;
