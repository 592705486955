///var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/Component/PostsList.js
import React from 'react';
import { Card } from 'primereact/card';
import { Paginator } from 'primereact/paginator';
import { Link } from 'react-router-dom';
import content from '../data/content.json';
import '../styles/PostsList.scss';

function PostsList({ posts, totalRecords, first, rows, setFirst, setRows }) {
  const onPageChange = (e) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  // Helper function to format date in DD/Mon/YYYY format
  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <div className="container posts-list-container">
      <h1>{content.title}</h1>
      <p>{content.description}</p>

      <div className="row">
        {posts.map(post => (
          <div key={post.ID} className="col-md-4 mb-4">
            <Link to={`/post/${post.ID}/${encodeURIComponent(post.post_title?.replace(/ /g, '-') || '')}`}>
              <Card
                title={post.post_title || 'Untitled'}
                subTitle={formatDate(post.post_modified) || 'No description available'}
              >
                <img
                  src={post.images && post.images[0]?.guid ? post.images[0].guid : 'https://afaa.website/s/ce99ba.webp'}
                  alt={post.post_title || 'Image'}
                  style={{ width: '100%' }}
                />
              </Card>
            </Link>
          </div>
        ))}
      </div>

      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords} 
        onPageChange={onPageChange}
        rowsPerPageOptions={[30, 60, 90]}
      />
    </div>
  );
}

export default PostsList;
