
// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/Component/RedirectToPostWithSlug.js

import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';

function RedirectToPostWithSlug({ posts }) {
  const { id } = useParams();
  const post = posts.find(p => p.ID === parseInt(id));
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!post) {
      setShowDialog(true);
      setTimeout(() => {
        setShowDialog(false);
        setIsLoading(false); // Stop loading if post not found
      }, 3000);
    } else {
      setIsLoading(false); // Stop loading if post found
    }
  }, [post]);

  const slug = post ? encodeURIComponent(post.post_title.replace(/ /g, '-')) : 'KHONRAKGAME';

  return (
    <>
      {/* Redirect only if loading is complete */}
      {!isLoading && post ? (
        <Navigate to={`/post/${id}/${slug}`} replace />
      ) : (
        <>
          <Dialog
            visible={showDialog}
            onHide={() => setShowDialog(false)}
            header="Notification"
            footer={null}
          >
            <p>Content not found. Redirecting to the homepage in 3 seconds...</p>
          </Dialog>
        </>
      )}
    </>
  );
}

export default RedirectToPostWithSlug;
