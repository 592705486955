///var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/Component/PostsSwiper.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay, Parallax } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/parallax';
import { Link } from 'react-router-dom';

function PostsSwiper({ posts }) {
    return (
        <>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, Autoplay, Parallax]}
                spaceBetween={50}
                navigation
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                parallax
                speed={800}
                breakpoints={{
                    640: { slidesPerView: 1 },
                    641: { slidesPerView: 2 }
                }}
            >
                {posts.map(post => (
                    <SwiperSlide key={post.ID}>
                        <Link to={`/post/${post.ID}/${post.post_title}`}>
                            <img 
                                src={post.images && post.images[0]?.guid ? post.images[0].guid : 'https://afaa.website/s/ce99ba.webp'}
                                alt={post.post_title || 'Image'}
                                className="custom-image"
                            />
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

export default PostsSwiper;
