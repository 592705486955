// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/Component/SearchPage.js
// /Component/SearchPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SearchPage.scss'; // Import the SCSS file

function SearchPage() {
    const [query, setQuery] = useState('');
    const navigate = useNavigate();

    const handleSearch = (e) => {
        e.preventDefault();
        if (query.trim()) {
            navigate(`/search/results?q=${query}`);
        }
    };

    return (
        <div className="search-page">
            <h1 className="search-title">AA-Chats Search for Games or Articles</h1>
            <form onSubmit={handleSearch} className="search-form">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Type your search here"
                    className="search-input"
                />
                <button type="submit" className="search-button">
                    Search
                </button>
            </form>
        </div>
    );
}

export default SearchPage;
