// /var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/Component/HelmetComponent.js
import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetComponent = () => (
        <Helmet>
          <title>KHONRAKGAME แหล่งรวมข้อมูลที่ครบครันสำหรับทุกคนที่หลงใหลในโลกของเทคโนโลยี และนวัตกรรมดิจิทัล! ไม่ว่าคุณจะเป็นผู้ที่ชื่นชอบ | คนรักเกม.COM</title>
          <meta name="description" content="ติดตามข่าวสารล่าสุด KHONRAKGAME ในวงการเกมทั้งในประเทศ และต่างประเทศ นำเสนอข่าวสารเกมใหม่ เกมมือถือ อัปเดตเกมดัง อีสปอร์ต และอื่นๆ อีกมากมาย | คนรักเกม.COM" />
          <meta name="keywords" content="ข่าวเกม , เกมมือถือ , อีสปอร์ต , เกมใหม่ , รับทำเว็บไซต์, รับทำ SEO , รับพัฒนาระบบ , DEVELOPER , คนรักเกม.COM , KHONRAKGAME , คนรักเกม " />
          <meta property="og:title" content="KHONRAKGAME: ข่าวเกม อีสปอร์ต เกมมือถือ อัปเดตครบถ้วน!" />
          <meta property="og:description" content="แหล่งรวมข่าวสารเกม อีสปอร์ต เกมมือถือ อัปเดตครบถ้วน! เข้าถึงทุกวงการเกม พร้อมความรู้และชุมชน | คนรักเกม.COM" />
          <meta property="og:image" content="https://afaa.website/s/ce99ba.webp" />
          <meta property="og:url" content="https://xn--12cah8fwbj2hqf.com/" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@AFRAAPACHE" />
          <meta name="twitter:title" content="KHONRAKGAME: ข่าวเกม อีสปอร์ต เกมมือถือ อัปเดตครบถ้วน!" />
          <meta name="twitter:description" content="แหล่งรวมข่าวสารเกม อีสปอร์ต เกมมือถือ อัปเดตครบถ้วน! เข้าถึงทุกวงการเกม พร้อมความรู้และชุมชน | คนรักเกม.COM" />
          <meta name="twitter:image" content="https://afaa.website/s/ce99ba.webp" />
          <link rel="canonical" href="https://xn--12cah8fwbj2hqf.com/" />
        </Helmet>
);

export default HelmetComponent;
