///var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/App.js

// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './styles/App.scss';  // Import SCSS for global styles

import { ProgressSpinner } from 'primereact/progressspinner';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/parallax';

import PostDetails from './Component/PostDetails';
import MenubarComponent from './Component/MenubarComponent';
import Footer from './Component/Footer';
import RedirectToPostWithSlug from './Component/RedirectToPostWithSlug';

import PostsSwiper from './Component/PostsSwiper';
import PostsList from './Component/PostsList';
import SearchPage from './Component/SearchPage';
import SearchResults from './Component/SearchResults';
import HelmetComponent from './Component/HelmetComponent';


function App() {
  const [posts, setPosts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(51);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  useEffect(() => {
    const offset = first;
    setIsLoading(true);

    fetch(`https://py.afaa.website/blog/api/game?offset=${offset}&limit=${rows}&order_by=ID&order_direction=DESC`)
      .then(response => response.json())
      .then(data => {
        setPosts(data.posts || []);            // Use posts from new data structure
        setTotalRecords(data.total_count || 0); // Use total_count for pagination
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, [first, rows]);


  useEffect(() => {
    const fetchData = async (date) => {
      setIsLoading(true);
      try {
        const url = `https://py.afaa.website/blog/api/game?order_by=ID&order_direction=DESC&modified=${date}`;
        const response = await fetch(url);
        const data = await response.json();
        setIsLoading(false);
        return data;
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        return [];
      }
    };

    const getDate = (days) => {
      const date = new Date();
      date.setDate(date.getDate() + days);
      return date.toISOString().split('T')[0];
    };

    const datesToFetch = [3, 4, 5].map(getDate); // Today, 3 days ahead, and 4 days ahead

    Promise.all(datesToFetch.map(fetchData)).then(dataSets => {
      const combinedPosts = dataSets.flat();
      setPosts(combinedPosts);
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <MenubarComponent />

        <HelmetComponent />


        <script type="application/ld+json">
          {`
  {
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    "headline": "KHONRAKGAME: ข่าวเกม อีสปอร์ต เกมมือถือ อัปเดตครบถ้วน!",
    "image": [
      "https://afaa.website/s/ce99ba.webp"
    ],
    "datePublished": "2024-04-26",
    "dateModified": "2024-04-26"
  }
`}
        </script>

        <Routes>
          <Route path="/" element={
            <div className="content">
              {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                  <ProgressSpinner />
                </div>
              ) : (
                <>
                  <PostsSwiper posts={posts} />
                  <PostsList
                    posts={posts}
                    totalRecords={totalRecords}
                    first={first}
                    rows={rows}
                    setFirst={setFirst}
                    setRows={setRows}
                  />
                </>
              )}
            </div>
          } />

          <Route path="/post/:id" element={<RedirectToPostWithSlug posts={posts} />} />
          <Route path="/post/:id/:slug" element={<PostDetails />} />

          <Route path="/search" element={<SearchPage />} />
          <Route path="/search/results" element={<SearchResults />} />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
